import { useGetPreferredCategoriesQuery } from "services/graphql";

export function useGetPreferredCategories() {
  const { data, loading, error } = useGetPreferredCategoriesQuery();

  const preferredCategories =
    data?.preferredCategories?.preferred?.categories || [];
  const preferredCategory1 = preferredCategories[0];
  const preferredCategory2 = preferredCategories[1];

  return {
    preferredCategory1,
    preferredCategory2,
    loading: loading || !data,
    error,
  };
}
